import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';
import { IVehicle, IVehicleColor, IVehicleForm, IVehicleModel } from '@/models';

import { http } from './http';
import { IGetParams, IListResponse } from './types';

export const VehicleApi = {
  getVehicles: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IVehicle>>> => {
    return http.get(`api/${ApiVersion.V1}/vehicles/vehicles`, { params });
  },
  getVehicle: (id: string): Promise<AxiosResponse<IVehicle>> => {
    return http.get(`api/${ApiVersion.V1}/vehicles/vehicles/${id}`);
  },
  getVehicleModels: (
    makeId: string,
    year: number,
  ): Promise<AxiosResponse<IVehicleModel[]>> => {
    return http.get(`api/${ApiVersion.V1}/vehicles/makes/${makeId}/models`, {
      params: { year },
    });
  },
  getVehicleColors: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IVehicleColor>>> => {
    return http.get(`api/${ApiVersion.V1}/vehicles/colors`, { params });
  },
  createVehicleColor: (
    data: IVehicleColor,
  ): Promise<AxiosResponse<IVehicleColor>> => {
    return http.post(`api/${ApiVersion.V1}/vehicles/colors`, data);
  },
  createVehicle: (data: IVehicleForm): Promise<AxiosResponse<IVehicle>> => {
    return http.post(`api/${ApiVersion.V1}/vehicles/vehicles`, data);
  },
  updateVehicle: ({
    _id,
    ...data
  }: IVehicleForm): Promise<AxiosResponse<IVehicle>> => {
    return http.put(`api/${ApiVersion.V1}/vehicles/vehicles/${_id}`, data);
  },
  deleteVehicle: (id: string): Promise<AxiosResponse<IVehicle>> => {
    return http.delete(`api/${ApiVersion.V1}/vehicles/vehicles/${id}`);
  },
};
