import { PropsWithChildren } from 'react';

import {
  Divider,
  Grid,
  Stack,
  StackProps,
  Typography,
  styled,
} from '@mui/material';
import clsx from 'clsx';

import { colors } from '@/theme/variables';

const FormCardRoot = styled(Stack)(({ theme }) => ({
  position: 'relative',
  width: 'calc(100% - 2px)',
  margin: '12px 1px 1px 1px',
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0px 0px 0px 1px #E7EAEC',
  ...(theme.palette.mode !== 'light' && {
    backgroundColor: colors.grey[80],
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
  }),

  '&:first-of-type': {
    marginTop: 0,
  },
  '& .cardTitle': {
    fontWeight: 500,
    padding: '12px 15px',
  },
  '& .cardContent': {
    marginLeft: '-16px',
    padding: '12px 15px',
  },
  '& .divider': {
    marginTop: '12px',
  },

  '&.kaseScrollableCard': {
    '&.kaseFormCard': {
      '.MuiTableCell-head': {
        paddingTop: 0,
      },
      '.cardContent': {
        marginTop: 0,
      },
    },
    '.cardContent': {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    '.MuiAutocomplete-root': {
      position: 'sticky',
      top: 0,
      backgroundColor: colors.white,
    },
  },

  '&.grey-title': {
    '.cardTitle': {
      backgroundColor: colors.grey[10],
      padding: '14px 10px',
      width: '100%',
      fontWeight: 500,
    },
    '.divider': {
      display: 'none',
    },
  },
}));

interface FormCardProps extends PropsWithChildren, StackProps {
  title?: string;
  classes?: { cardContainer?: string; cardContent?: string };
}

export const FormCard = ({
  title,
  classes,
  children,
  style,
  sx,
}: FormCardProps) => {
  return (
    <FormCardRoot
      spacing={1.5}
      divider={title ? <Divider className="divider" /> : ''}
      className={classes?.cardContainer}
      style={style}
      sx={sx}
    >
      {title && <Typography className="cardTitle">{title}</Typography>}
      <Grid
        columnSpacing={2}
        container
        className={clsx('cardContent', classes?.cardContent)}
      >
        {children}
      </Grid>
    </FormCardRoot>
  );
};
