import { ReactNode } from 'react';

import { Box } from '@mui/material';

import { MasterNameApi } from '@/api';
import { TabLayoutContextProvider } from '@/layouts/TabLayout';
import { MasterNamesContextProvider } from '@/layouts/TabLayout/MasterNames';
import { CFSMasterNameInvolvement } from '@/models';

interface MasterIndexOrganizationBaseProps {
  children: ReactNode;
}

const MasterIndexOrganizationBase = ({
  children,
}: MasterIndexOrganizationBaseProps) => {
  return (
    <TabLayoutContextProvider>
      <MasterNamesContextProvider
        nextRoutes={[]}
        handleSubmit={() => {}}
        updateMasterName={(nameId: string, data) => {
          return MasterNameApi.updateMasterName({ _id: nameId, ...data });
        }}
        getMasterName={(nameId: string) => MasterNameApi.getMasterName(nameId)}
        involvementOptions={CFSMasterNameInvolvement}
      >
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          sx={{ overflowY: 'auto', backgroundColor: 'white' }}
        >
          {children}
        </Box>
      </MasterNamesContextProvider>
    </TabLayoutContextProvider>
  );
};

export default MasterIndexOrganizationBase;
