import { ReactNode, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Grid } from '@mui/material';

import { UserApi } from '@/api';
import { RenderFormField } from '@/components';
import {
  AgeGroup,
  CFSMasterNameInvolvement,
  ICFSMasterName,
  IKaseInvolvementForm,
  IKaseMasterName,
  IMasterNamePerson,
  InjuryType,
  KaseMasterNameInvolvement,
  KaseMasterOrganizaionInvolvement,
  kaseOffenderForm,
  kaseVictimForm,
  LevelOfInjury,
  MasterNameBehaviors,
  MasterNameType,
  MedicalTreatment,
  OffenderType,
  VictimResidenceStatus,
  VictimType,
} from '@/models';
import { useToastStore } from '@/store';

import { MasterNameKaseInvolvementPopover } from './components/MasterNameKaseInvolvementPopover';
import {
  IFieldItem,
  OrganizationInvolvementKaseDisabled,
  OrganizationInvolvementKaseHidden,
  VictimTypeSelectItems,
  kasesInvolvementFields,
  setInvolvementKaseFormFields,
} from './data';

interface InvolvementFormProps {
  isReadOnlyNames: boolean;
  involvementOptions:
    | typeof KaseMasterNameInvolvement
    | typeof CFSMasterNameInvolvement;
  hasPredefinedInvolvement?: boolean;
  currentInvolvement?: KaseMasterNameInvolvement;
  currentMastername?: ICFSMasterName | IKaseMasterName;
}

export const InvolvementForm = ({
  isReadOnlyNames,
  currentMastername,
  involvementOptions,
  currentInvolvement,
  hasPredefinedInvolvement = false,
}: InvolvementFormProps) => {
  const [conditionalFieldsTest, setConditionalFields] = useState({});
  const { resetField, watch } = useFormContext();
  const { updateToast } = useToastStore();
  const [employeeList, setEmployeeList] = useState<
    {
      value: any;
      label: string;
    }[]
  >([]);

  useEffect(() => {
    fetchOfficers();
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      let updatedFields = {};
      if ((value as kaseOffenderForm)?.offenderType) {
        const offenderTypeValue = (value as kaseOffenderForm).offenderType;

        updatedFields = {
          ...updatedFields,
          checkOffenderType: offenderTypeValue === OffenderType.CHARGED,
        };

        updatedFields = {
          ...updatedFields,
          checkWasArrested: !!(value as kaseOffenderForm)?.wasArrested,
        };
      }

      if ((value as kaseVictimForm).victimType) {
        updatedFields = {
          ...updatedFields,
          isOfficerVictim:
            (value as kaseVictimForm).victimType ===
            VictimType.LAW_ENFORCEMENT_OFFICER,
        };
      }

      if (value?.ageGroup || !!value?.ageGroup === false) {
        const personDobNotSet =
          currentMastername?.masterName?.type === MasterNameType.PERSON &&
          !!(currentMastername?.masterName as IMasterNamePerson)?.birthday ===
            false;

        updatedFields = {
          ...updatedFields,
          ...((value?.ageGroup === AgeGroup.UNKNOWN ||
            value?.ageGroup === AgeGroup.OVER_NINETY_EIGHT_YEARS_OLD) && {
            age: undefined,
          }),
          checkExactAge:
            personDobNotSet && value.ageGroup === AgeGroup.EXACT_AGE,
          checkAgeRange:
            personDobNotSet && value.ageGroup === AgeGroup.AGE_RANGE,
          checkDobNotSet: personDobNotSet,
        };
      }

      setConditionalFields((state) => ({
        ...state,
        ...updatedFields,
      }));
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const fetchOfficers = async () => {
    const filterParams = {
      'roles.role': 'POLICE',
    };
    try {
      const { data } = await UserApi.get({
        limit: 1000,
        filter: JSON.stringify(filterParams),
      });
      const officersMap = data.results.map((user) => ({
        value: user._id,
        label: `${user.firstName} ${user.lastName}`,
      }));
      setEmployeeList([...officersMap]);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const handleInputChange = (val: string, field: IFieldItem) => {
    const isAgeGroupChange = field.name.toLowerCase() === 'agegroup';
    const isInvolvementChange = field.name.toLowerCase() === 'involvement';
    if (!isAgeGroupChange && !isInvolvementChange) return;

    // User removes value in age group field
    if (isAgeGroupChange && !val) {
      resetField('ageGroup');
      resetField('age');
      resetField('ageRange');
    }

    // User changes involvement
    if (isInvolvementChange && val !== currentInvolvement) {
      resetField('ageGroup');
      resetField('age');
      resetField('ageRange');
    }
  };

  const getOptionItems: { [key: string]: any } = {
    getUsers: employeeList,
    getBehaviorItems: Object.entries(MasterNameBehaviors).map(
      ([value, label]) => ({ label, value }),
    ),
    getInvolvementItems: Object.entries(
      currentMastername?.masterName?.type === MasterNameType.ORGANIZATION
        ? KaseMasterOrganizaionInvolvement
        : involvementOptions,
    ).map(([value, label]) => ({ label, value })),
  };

  const renderRelatedFields = (): ReactNode => {
    if (!currentInvolvement) {
      return;
    }
    const fields = kasesInvolvementFields.get(currentInvolvement);
    return fields ? renderFormFields(fields) : <></>;
  };

  const renderFormFields = (fieldsArray: Array<IFieldItem>) => {
    return (
      <Grid flex={1} container columnSpacing={3} sx={{ mt: 2 }}>
        {fieldsArray.map((field) => {
          const { shouldDisplayCallback, ...fieldRest } = field;
          const shouldRender = shouldDisplayCallback
            ? conditionalFieldsTest[
                shouldDisplayCallback as keyof typeof conditionalFieldsTest
              ]
            : true;
          let isDisabled = isReadOnlyNames;
          let popOver = null;

          if (
            fieldRest.name === 'victimType' &&
            currentMastername?.masterName.type === MasterNameType.PERSON
          ) {
            // If masterName type is PERSON, show these items in the dropdown
            const personVictimTypes = [
              `${VictimType.FIRE_FIGHTER} = Firefighter`,
              `${VictimType.INDIVIDUAL} = Individual`,
              `${VictimType.LAW_ENFORCEMENT_OFFICER} = Law Enforcement Officer (LEOKA)`,
              `${VictimType.OTHER} = Other`,
              `${VictimType.UNKNOWN} = Unknown`,
            ];

            field.items = VictimTypeSelectItems.filter((item) =>
              personVictimTypes.includes(item.label),
            );
          } else if (
            fieldRest.name === 'victimType' &&
            currentMastername?.masterName.type === MasterNameType.ORGANIZATION
          ) {
            // If masterName type is ORGANISATION, show these items in the dropdown
            const personVictimTypes = [
              `${VictimType.FIRE_FIGHTER} = Firefighter`,
              `${VictimType.INDIVIDUAL} = Individual`,
              `${VictimType.LAW_ENFORCEMENT_OFFICER} = Law Enforcement Officer (LEOKA)`,
            ];

            field.items = VictimTypeSelectItems.filter(
              (item) => !personVictimTypes.includes(item.label),
            );
          }
          if (
            fieldRest.name === 'involvement' &&
            currentMastername?.relationship?.involvement
          ) {
            isDisabled = true;
            popOver = <MasterNameKaseInvolvementPopover />;
          }
          if (
            currentMastername?.masterName?.type === MasterNameType.ORGANIZATION
          ) {
            if (OrganizationInvolvementKaseHidden.includes(fieldRest.name)) {
              return null;
            }
            if (OrganizationInvolvementKaseDisabled.includes(fieldRest.name)) {
              isDisabled = true;
            }
          }
          return shouldRender ? (
            <Grid
              item
              key={fieldRest.name}
              xs={6}
              lg={4}
              sx={{ position: 'relative' }}
            >
              <RenderFormField
                key={fieldRest.name}
                disabled={isDisabled}
                {...fieldRest}
                items={
                  typeof fieldRest.items === 'string' &&
                  fieldRest.items in getOptionItems
                    ? getOptionItems[fieldRest.items as string]
                    : fieldRest.items
                }
                label={fieldRest.label}
                onInputChange={(e) => handleInputChange(e, field)}
              />
              {popOver}
            </Grid>
          ) : null;
        })}
      </Grid>
    );
  };

  return (
    <>
      {hasPredefinedInvolvement &&
        renderFormFields(setInvolvementKaseFormFields)}
      {renderRelatedFields()}
    </>
  );
};

export default InvolvementForm;

InvolvementForm.prepareData = (
  values: IKaseInvolvementForm,
  currentInvolvement: KaseMasterNameInvolvement | undefined,
  updateToast: (arg0: { message: string; open: boolean }) => void,
) => {
  if (!currentInvolvement) return;
  const fields = kasesInvolvementFields.get(currentInvolvement);
  if (!fields) {
    updateToast({ message: 'invalid involvement ', open: true });
    return;
  }
  const data = {
    relationship: {
      behavior: values.behavior,
      involvement: values.involvement,
    },
  } as IKaseMasterName;

  const additionalInfo = Object.values(fields).reduce((accumulated, item) => {
    let currentValue = values[item.name as keyof typeof values];

    if (
      currentValue === undefined ||
      currentValue === null ||
      currentValue == ''
    ) {
      return accumulated;
    }
    if (item.name === 'age' || item.name === 'ageRange') {
      if (
        values.ageGroup === AgeGroup.UNKNOWN ||
        values.ageGroup === AgeGroup.OVER_NINETY_EIGHT_YEARS_OLD
      ) {
        return accumulated;
      }

      // if age group is age_range
      // but value isn't array, convert to array
      if (values.ageGroup === AgeGroup.AGE_RANGE) {
        currentValue = Array.isArray(currentValue)
          ? currentValue
          : [0, Number(currentValue)];
      }

      currentValue = Array.isArray(currentValue)
        ? currentValue
            .map((rangeItem) => rangeItem.toString().padStart(2, '0'))
            .join('')
        : currentValue.toString().padStart(4, '0');

      return {
        ...accumulated,
        age: currentValue,
      };
    }

    if (
      item.name == 'wasArrested' &&
      (values as kaseOffenderForm).offenderType !== OffenderType.CHARGED
    ) {
      return {
        ...accumulated,
        wasArrested: undefined,
      };
    }

    return {
      ...accumulated,
      [item.name]: currentValue,
    };
  }, {} as Record<string, any>);
  data.additionalInfo = additionalInfo;

  return data;
};

InvolvementForm.prepareReset = (res: IKaseMasterName) => {
  let ageValue = res.additionalInfo?.age;
  if (ageValue) {
    ageValue =
      res.additionalInfo?.ageGroup === AgeGroup.AGE_RANGE
        ? [Number(ageValue.slice(0, 2)), Number(ageValue.slice(2))]
        : +ageValue;
  }

  let resetVals = {
    ...res.relationship,
    _id: res.masterName._id,
    ...res.additionalInfo,
    wasArrested: res.additionalInfo?.wasArrested || undefined,
    age: ageValue || undefined,
    ageRange: ageValue || undefined,
    ageGroup: res.additionalInfo?.ageGroup || undefined,
    interviewedBy: res.additionalInfo?.interviewedBy?._id || undefined,
  };

  if (res.masterName.type === MasterNameType.ORGANIZATION) {
    const organizationType = res.masterName.organizationType
      ?.toString()
      .toLowerCase()
      .replaceAll('_', ' ');

    const victimType = organizationType?.toUpperCase() || '';

    const residenceStatus = VictimResidenceStatus.NOT_APPLICABLE;
    const levelOfInjury = LevelOfInjury.NOT_APPLICABLE;
    const injuryType = [InjuryType.NOT_APPLICABLE];
    const medicalTreatment = MedicalTreatment.NOT_APPLICABLE;

    resetVals = {
      ...resetVals,
      behavior: MasterNameBehaviors['N/A'],
      involvement: KaseMasterNameInvolvement.VICTIM,
      age: resetVals?.age ? resetVals.age : undefined,
      ageRange: resetVals?.ageRange ? resetVals.ageRange : undefined,
      residenceStatus,
      levelOfInjury,
      medicalTreatment,
      ...(injuryType && { injuryType }),
      ...(organizationType && victimType && { victimType: victimType[0] }),
    };
  }
  return resetVals;
};
