import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { MasterNameView } from '@/layouts/TabLayout/MasterNames/components/MasterNameView';

import MasterIndexPersonBase from './MasterIndexPersonBase';

const MasterIndexPersonView = () => {
  const navigate = useNavigate();

  return (
    <MasterIndexPersonBase>
      <Box sx={{ backgroundColor: 'white', padding: 2 }}>
        <MasterNameView
          onGoBack={() => navigate('/master-index/persons')}
          hideHeader={true}
          isMasterIndex={true}
        />
      </Box>
    </MasterIndexPersonBase>
  );
};

export default MasterIndexPersonView;
