import { useEffect, useMemo, useRef, useState } from 'react';

import { Button, InputLabel, Stack, Typography } from '@mui/material';

import { AddressApi } from '@/api';
import { IcPlusCircle } from '@/assets/images';
import { IAddressForm } from '@/models';
import { useToastStore } from '@/store';

import { AddressSearch, AddressOptionType } from './AddressSearch';
import { BaseFieldProps } from '../../FormElements';

interface AddressItemProps extends BaseFieldProps {
  name?: string;
  value?: AddressOptionType;
  updateCurrentAddress: (newAddress: IAddressForm | null) => void;
}

export const SubAddressItem = (props: AddressItemProps) => {
  const { value, updateCurrentAddress } = props;
  const subAddressInputRef = useRef<HTMLInputElement>();
  const { updateToast } = useToastStore();
  const [inputValue, setInputValue] = useState<string>('');
  const [subAddressOptions, setSubAddressOptions] = useState<IAddressForm[]>(
    [],
  );

  const { addressNumber, completeStreetName, zipcode, businessName } =
    value || {};

  const enableSubAddressField =
    !!addressNumber || !!completeStreetName || !!zipcode;

  const hideAddButton = useMemo(() => {
    if (!inputValue) return true;
    const matchedOption = subAddressOptions.find(
      ({ subAddress }) =>
        subAddress?.toLowerCase().trim() === inputValue.toLowerCase().trim(),
    );
    return !!matchedOption;
  }, [inputValue, subAddressOptions]);

  useEffect(() => {
    if (enableSubAddressField) {
      fetchAddress();
    } else {
      setSubAddressOptions([]);
    }
  }, [addressNumber, completeStreetName, zipcode]);

  const fetchAddress = async () => {
    try {
      const params = {
        filter: JSON.stringify({
          addressNumber,
          completeStreetName,
          zipcode,
        }),
      };
      const res = await AddressApi.getAddresses(params);
      setSubAddressOptions(res.data.results);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const addNewAddress = async () => {
    try {
      if (value) {
        const newAddressData = {
          ...value,
          _id: undefined,
          stateAddressPointId: undefined,
          subAddress: inputValue,
        };

        const res = await AddressApi.createAddress(newAddressData);
        setSubAddressOptions((v) => [...v, res.data]);
        updateCurrentAddress(res.data);
      }
    } catch (err: any) {
      updateToast({
        open: true,
        message:
          err.message || 'Failed to create sub-address. Please try again.',
      });
    }
    subAddressInputRef.current?.blur();
  };

  const handleChange = (newValue: AddressOptionType | string | null) => {
    if (!newValue) {
      const mainAddress = subAddressOptions.find(
        ({ subAddress }) => !subAddress,
      );
      if (mainAddress) {
        updateCurrentAddress({
          ...mainAddress,
          subAddress: '',
        });
      }
    } else if (typeof newValue !== 'string') {
      updateCurrentAddress(newValue);
    }
  };

  const getSubAddressOptionLabel = (option?: AddressOptionType | string) => {
    if (typeof option === 'string') return option;
    return option?.subAddress || '';
  };

  return (
    <Stack className="address-item" alignItems="center">
      <Stack flex={1}>
        <InputLabel sx={{ mt: 1 }} disabled={!enableSubAddressField}>
          Sub-address
        </InputLabel>
        <AddressSearch
          inputRef={subAddressInputRef}
          value={value}
          options={subAddressOptions.slice(1)}
          onChange={(_, selected) => {
            handleChange(selected);
          }}
          getOptionLabel={getSubAddressOptionLabel}
          handleInputChange={setInputValue}
          renderOption={(option) => (
            <Typography variant="subtitle2">{option.subAddress}</Typography>
          )}
          extraOptionItem={
            <Button
              color="error"
              onMouseDown={(ev) => ev.preventDefault()}
              onClick={addNewAddress}
              sx={{
                width: '100%',
                display: hideAddButton ? 'none' : 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginBottom: 1,
                paddingLeft: 3,
              }}
            >
              <IcPlusCircle style={{ marginRight: 5 }} />
              {`Create ${inputValue} as a new item`}
            </Button>
          }
          disabled={!enableSubAddressField}
        />
      </Stack>
      <Typography className="txt-busines-name">{businessName}</Typography>
    </Stack>
  );
};
