import { FC } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
  styled,
} from '@mui/material';

import { FormElementProps } from '@/components';
import colors from '@/theme/variables/colors';

export interface FormCheckboxFieldProps
  extends Omit<CheckboxProps, 'name' | 'onChange'>,
    FormElementProps {
  label?: string;
  handleChange?: (name: string, value: boolean) => void;
}

const Root = styled(Box)(() => ({
  flex: 1,
  minWidth: '100%',
  maxWidth: '100%',
  marginBottom: 8,
  '& .MuiInputBase-root': {
    width: '100%',
  },
  '& .checkbox': { paddingTop: 0, paddingBottom: 0 },
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    color: colors.grey[80],
    marginTop: '1.5px',
  },
}));

export const FormCheckboxField: FC<FormCheckboxFieldProps> = (props) => {
  const { label, name, rules, handleChange, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <Root>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox
                className="checkbox"
                color="error"
                {...rest}
                {...field}
                checked={field.value || rest.value}
                onChange={(e) => {
                  field.onChange(e);
                  if (handleChange) {
                    handleChange(name, e.target.checked);
                  }
                }}
              />
            }
            label={label}
          />
        )}
      />
      <FormHelperText error>{error?.message}</FormHelperText>
    </Root>
  );
};
