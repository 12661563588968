import { MouseEvent, useState, useMemo } from 'react';

import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';

import { IcDotSpread } from '@/assets/images/svgs';
import { ConfirmationDialog, TableColumn, TableContainer } from '@/components';
import { DATE_FORMAT } from '@/config';
import { INote } from '@/models/note';
import { useUserStore } from '@/store';
import { colors } from '@/theme/variables';

import { useMasterNamesContext } from '../..';
import { tabNameNotesTableColumns } from '../../data';

export interface NameNotesTableProps {
  rows: INote[];
  deleteNode: (noteId: string) => void;
  editNote: (noteId?: string) => void;
  personId?: string;
  isMasterIndex?: boolean;
}
export interface MenuState {
  menuEl: HTMLElement | null;
  selectedId?: string;
}

export const NameNotesTable = ({
  rows,
  deleteNode,
  editNote: editNote,
  personId,
  isMasterIndex = false,
}: NameNotesTableProps) => {
  const { currentMastername } = useMasterNamesContext();
  if (!currentMastername?.masterName?._id && personId === undefined) {
    return <>invalid master name </>;
  }
  const { user } = useUserStore();

  const [menuState, setMenuState] = useState<MenuState>({
    menuEl: null,
  });

  const { menuEl } = menuState;
  const openMenu = Boolean(menuEl);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const handleMenuClose = () => {
    setMenuState({ menuEl: null });
  };

  const handleMenuOpen = (
    event: MouseEvent<HTMLButtonElement>,
    currentId?: string,
  ) => {
    event.stopPropagation();
    setMenuState({ menuEl: event.currentTarget, selectedId: currentId });
  };

  const renderColumn = (row: INote, field: { key: string; label: string }) => {
    const data = row[field.key as keyof typeof row];

    switch (field.key) {
      case 'createdBy': {
        const { firstName, lastName } = row.createdBy;
        return `${firstName ? firstName : 'no name'} ${
          lastName ? lastName : 'no lastName'
        }`;
      }
      case 'cfs': {
        return row?.cfs
          ? row.cfs.number
          : row?.kase
          ? row.kase.number
          : 'no number';
      }
      case 'createdAt':
        return moment(data as string).format(DATE_FORMAT);
      default:
        return data?.toString();
    }
  };

  const renderRows = useMemo(() => {
    if (rows.length === 0)
      return (
        <TableRow>
          <TableCell
            colSpan={tabNameNotesTableColumns.length + 1}
            sx={{ textAlign: 'center' }}
          >
            No notes found
          </TableCell>
        </TableRow>
      );
    return rows.map((row: INote) => (
      <TableRow key={row._id}>
        {tabNameNotesTableColumns.map((field: TableColumn) => {
          return (
            <TableCell key={field.key}>{renderColumn(row, field)}</TableCell>
          );
        })}
        {!isMasterIndex && (
          <TableCell key={row._id} sx={{ width: '120px' }}>
            {user?._id === row.createdBy._id && (
              <IconButton onClick={(e) => handleMenuOpen(e, row._id)}>
                <IcDotSpread />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>
    ));
  }, [rows]);
  return (
    <TableContainer sx={{ px: 0 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {tabNameNotesTableColumns.map((field) => (
              <TableCell key={field.key}>{field.label}</TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderRows}</TableBody>
      </Table>
      <Menu
        id="basic-menu"
        anchorEl={menuEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            editNote(menuState.selectedId);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          className="error"
          onClick={() => setShowConfirm(true)}
          sx={{ color: colors.error.main }}
        >
          Delete
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        title="Delete Note?"
        description="Warning! You are about to delete this note, are you sure?"
        confirmText="Delete"
        open={showConfirm}
        onCancel={() => {
          setShowConfirm(false);
          setMenuState({ menuEl: null });
        }}
        onConfirm={() => {
          if (menuState.selectedId) deleteNode(menuState.selectedId);
          setShowConfirm(false);
          setMenuState({ menuEl: null });
        }}
      />
    </TableContainer>
  );
};
