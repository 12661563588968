import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Chip, Stack, styled } from '@mui/material';
import moment from 'moment';

import { MasterNameApi } from '@/api';
import {
  IViewCard,
  IViewItem,
  FileGrid,
  ViewCard,
  ViewItem,
} from '@/components';
import { DATE_FORMAT } from '@/config';
import { GenderList, RaceList } from '@/constants/form_inputs';
import {
  PageBackHeader,
  TabAnimationWrapper,
  useTabLayoutContext,
} from '@/layouts/TabLayout';
import { IMasterName, IMasterNamePerson, IPhoneNumber } from '@/models';
import { getAddressLocation } from '@/services';
import { useToastStore } from '@/store';
import { colors } from '@/theme/variables';
import {
  convertToAsteriskSSN,
  flattenObject,
  formatPhoneNumber,
} from '@/utils';

import { useMasterNamesContext } from '..';
import {
  MasterNameViewFields,
  TabNamesNextPaths,
  TabNamesScreens,
} from '../data';
import { CFSInvolvementHistory } from './tables/CFSInvolvementHistory';

const LinkButton = styled(Button)(() => ({
  textDecoration: 'underline',
  color: colors.error.main,
  fontWeight: 'normal',
}));

type MasterNameViewProps = {
  hideHeader?: boolean;
  onGoBack?: () => void;
  isMasterIndex?: boolean;
};

export const MasterNameView = ({
  hideHeader,
  isMasterIndex,
  ...props
}: MasterNameViewProps) => {
  const { updateToast } = useToastStore();
  const { currentMastername, linkedMasterNames, setCurrentMastername } =
    useMasterNamesContext();
  const [currentName, setCurrentName] = useState<IMasterName>();
  const { updateCurrentScreen, currentScreen } = useTabLayoutContext();

  const { id: personId } = useParams();

  const relationship = linkedMasterNames.find(
    (item) => item.masterName?._id === currentMastername?.masterName._id,
  )?.relationship;

  const flattenNameObj = flattenObject({
    ...currentName,
    relationship,
  });

  useEffect(() => {
    if (currentMastername?.masterName._id) fetchCurrentMastername();
  }, [currentMastername?.masterName._id]);

  useEffect(() => {
    if (personId) {
      fetchCurrentMastername();
    }
  }, [personId]);

  const fetchCurrentMastername = async () => {
    try {
      const res = personId
        ? await MasterNameApi.getMasterName(personId)
        : await MasterNameApi.getMasterName(
            String(currentMastername?.masterName._id),
          );
      setCurrentName(res.data);
      setCurrentMastername({
        masterName: res.data,
        relationship: {},
        kaseId: '',
        createdAt: new Date(),
        updatedAt: new Date(),
      });
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const onGoBack = () => {
    if (props.onGoBack) {
      return props.onGoBack();
    }
    updateCurrentScreen(TabNamesScreens.NamesList);
  };

  const renderViewCardContent = ({ key, items, size }: IViewCard) => {
    if (key === 'photos') {
      return (
        <ViewItem sx={{ mt: -4 }} md={8}>
          <FileGrid files={flattenNameObj.files} />
        </ViewItem>
      );
    }

    return items.map((item: IViewItem & { method?: any }) => {
      let value;
      if (item && item?.method && item.method in linkRenders) {
        value = linkRenders[item.method](flattenNameObj[item.value as string]);
      } else if (item.value?.toString().includes('address')) {
        let fullAddress;
        switch (item.value) {
          case 'personalInfo.address':
            fullAddress = currentName?.address;
            break;
          case 'driverLicense.address.addressLabel':
            fullAddress = (currentName as IMasterNamePerson)?.driverLicense
              ?.address;
            break;
          default:
            fullAddress = (currentName as IMasterNamePerson)?.employerInfo
              ?.address;
        }
        value = getAddressLocation(fullAddress ?? undefined);
        return <ViewItem key={item.label} md={4} {...item} value={value} />;
      } else if (
        item.value === 'birthday' ||
        item.value === 'driverLicense.birthday'
      ) {
        const birthday = flattenNameObj.birthday;
        value = birthday ? moment(birthday).format(DATE_FORMAT) : '';
      } else if (item.value === 'ssn') {
        value = convertToAsteriskSSN(flattenNameObj[item.value as string]);
      } else if (item.value === 'race') {
        value = RaceList[flattenNameObj.race as keyof typeof RaceList];
      } else if (item.value === 'sex') {
        value = GenderList[flattenNameObj.sex as keyof typeof GenderList];
      } else if (item.value === 'driverLicense.name') {
        value = `${flattenNameObj['driverLicense.firstName'] || ''} ${
          flattenNameObj['driverLicense.lastName'] || ''
        }`;
      } else if (item.value === 'personalInfo.phoneNumbers') {
        return flattenNameObj.phoneNumbers?.map((phoneItem: IPhoneNumber) => (
          <ViewItem
            key={phoneItem.type}
            label={phoneItem.type}
            md={2}
            {...phoneItem}
            value={formatPhoneNumber(phoneItem.phoneNumber)}
          />
        ));
      } else if (item.value === 'employerInfo.phoneNumber') {
        return (
          <ViewItem
            key={item.label}
            label={item.label}
            md={2}
            value={formatPhoneNumber(
              flattenNameObj['employerInfo.phoneNumber'],
            )}
          />
        );
      } else {
        value = flattenNameObj[item.value as string];
      }

      return (
        <ViewItem key={item.label} md={12} {...item} {...size} value={value} />
      );
    });
  };

  const linkRenders: {
    [key: string]: (value?: string) => ReactElement;
  } = {
    viewAllNotes: () => (
      <LinkButton
        onClick={() => updateCurrentScreen(TabNamesScreens.MasterNameNotes)}
      >
        View all notes
        <Chip color="error" size="small" label="3" sx={{ ml: 1 }} />
      </LinkButton>
    ),
    KasesInvolvementHistory: () => (
      <LinkButton
        onClick={() =>
          updateCurrentScreen(TabNamesScreens.KasesInvolvementHistory)
        }
      >
        View all cases
        <Chip color="error" size="small" label="3" sx={{ ml: 1 }} />
      </LinkButton>
    ),
    viewCfsHistory: () => (
      <LinkButton
        onClick={() =>
          updateCurrentScreen(TabNamesScreens.CFSInvolvementHistory)
        }
      >
        View all CFS&apos;s
        <Chip
          color="error"
          size="small"
          label={(currentName as IMasterNamePerson)?.linkedCFSs || 0}
          sx={{ ml: 1, aspectRatio: 1, '& .MuiChip-label': { p: 0 } }}
        />
      </LinkButton>
    ),
    checkIsVerified: (currentValue) => {
      return <>{currentValue ? 'Verified' : 'Not Verified'}</>;
    },
  };

  if (currentScreen === TabNamesScreens.CFSInvolvementHistory) {
    return <CFSInvolvementHistory />;
  }

  return (
    <TabAnimationWrapper nextPaths={TabNamesNextPaths.MasterNameView}>
      {!hideHeader && (
        <PageBackHeader title="View person details" goBack={onGoBack} />
      )}

      <Stack
        overflow="auto"
        sx={{
          '.p-kase-edit &': {
            p: 2.5,
            background: 'white',
            borderRadius: 2,
          },
        }}
      >
        {MasterNameViewFields.map((data, index) => (
          <ViewCard
            key={data.key}
            title={data.title}
            showDivider={index !== MasterNameViewFields.length - 1}
          >
            {renderViewCardContent(data)}
          </ViewCard>
        ))}
      </Stack>
    </TabAnimationWrapper>
  );
};
