import { colors } from '../variables';

export default {
  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }: any) => ({
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '1.25rem',
        marginBottom: 4,
        color: colors.grey[100],
        ...(theme.palette.mode !== 'light' && {
          color: 'white',
        }),
        '&.Mui-focused': {
          color: colors.grey[100],
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme }: any) => ({
        minHeight: 36,
        height: 36,
        padding: '4px 10px',
        backgroundColor: 'transparent!important',
        fontSize: '0.875rem',
        fontWeight: 400,
        color: colors.grey[80],
        borderRadius: 6,
        border: `1px solid ${colors.grey[20]}`,
        ...(theme.palette.mode !== 'light' && {
          color: colors.grey[20],
          border: `1px solid ${colors.grey[60]}`,
        }),

        '&.MuiInputBase-multiline': {
          height: 'auto',
        },
        '&.MuiInput-underline': {
          '&:after': {
            borderBottom: `1px solid ${colors.grey[80]}`,
          },
        },
        '& input': {
          fontSize: '0.875rem',
          fontWeight: 400,
        },

        '& .MuiOutlinedInput-input': {
          padding: 0,
        },

        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 0,
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '0px!important',
        },

        '&.Mui-disabled': {
          backgroundColor: '#e4e4ed!important',
          cursor: 'not-allowed',
        },
        '& .MuiAutocomplete-input': {
          height: 0,
          padding: 0,
          minWidth: '0!important',
        },

        '& .MuiAutocomplete-tag': {
          marginTop: 0,
          marginBottom: 0,
          height: 22,
        },

        '& .MuiChip-root': {
          height: 22,
          marginTop: 3,
          marginBottom: 0,

          '&:first-of-type': {
            marginTop: 0,
          },
        },
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: () => ({
        color: colors.grey[20],
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }),
    },
  },
};
