import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button, Divider, Grid, Stack } from '@mui/material';

import { MasterIndexApi, MasterNameApi } from '@/api';
import {
  ConfirmationDialog,
  FormRoot,
  RenderFormField,
  ViewCard,
} from '@/components';
import { withLoader, WithLoaderProps } from '@/hocs';
import withUnsavedChangesWarning, {
  WithUnsavedChangesWarningProps,
} from '@/hocs/withUnsavedChangesWarning';
import { useFilesValidation } from '@/hooks';
import {
  PageBackHeader,
  TabAnimationWrapper,
  useTabLayoutContext,
} from '@/layouts/TabLayout';
import {
  KaseMasterNameInvolvement,
  IMasterNamePerson,
  IPhoneNumber,
  IKaseMasterName,
} from '@/models';
import { uploadFiles } from '@/services';
import { useToastStore } from '@/store';
import { parsePhoneNumber } from '@/utils';

import { MasterNamePhoneNumberForm } from './MasterNamePhoneNumberForm';
import {
  MasterNameFormFields,
  TabNamesNextPaths,
  TabNamesScreens,
} from '../data';
import { useMasterNamesContext } from '../MasterNamesContext';

export type MasterNameFormData = IMasterNamePerson & {
  newPhoneNumber: IPhoneNumber;
  ssn_prev: string;
};

type MasterNameFormComponentProps = {
  hideHeader?: boolean;
  onGoBack?: () => void;
  isMasterIndex?: boolean;
};

export const MasterNameForm = ({
  showLoader,
  hideLoader,
  setIsDirty,
  onNavigation,
  hideHeader,
  isMasterIndex,
  ...props
}: WithLoaderProps &
  WithUnsavedChangesWarningProps &
  MasterNameFormComponentProps) => {
  const { updateToast } = useToastStore();
  const { kaseId } = useParams();
  const {
    currentMastername,
    setCurrentMastername,
    handleAddLinkedName,
    setCurrentDriverLicenseId,
  } = useMasterNamesContext();
  const { updateCurrentScreen } = useTabLayoutContext();
  const [showConfirm, setShowConfirm] = useState(false);
  const { handleSubmit, getValues, register, watch } =
    useFormContext<MasterNameFormData>();

  const uploadedFileItems = watch('files');
  const hasInvalidFiles = useFilesValidation(uploadedFileItems);

  const onGoBack = () => {
    if (props.onGoBack) {
      return props.onGoBack();
    } else {
      const values = getValues();
      updateCurrentScreen(
        values._id ? TabNamesScreens.NamesList : TabNamesScreens.MasterAddName,
      );
    }
  };

  const onError = () => {
    setShowConfirm(false);
  };
  const onSubmit = async (values: MasterNameFormData) => {
    setIsDirty(false);
    try {
      showLoader();
      const {
        files,
        employerInfo,
        driverLicense,
        phoneNumbers,
        newPhoneNumber,
      } = values;

      if (newPhoneNumber && newPhoneNumber?.phoneNumber !== '') {
        phoneNumbers?.push(newPhoneNumber);
      }
      const parsedPhoneNumbers = phoneNumbers
        ?.filter(({ type, phoneNumber }) => {
          return type && phoneNumber !== '';
        })
        .map(({ type, phoneNumber }) => ({
          type,
          phoneNumber: parsePhoneNumber(phoneNumber, '+1'),
        }));
      const payload = {
        ...values,
        address: values.address?._id,
        employerInfo: {
          ...employerInfo,
          address: employerInfo?.address?._id,
          phoneNumber: parsePhoneNumber(values.employerInfo?.phoneNumber, '+1'),
        },
        phoneNumbers: parsedPhoneNumbers,
        driverLicense: driverLicense?._id,
      };

      if (files && files.length) {
        const uploadedFiles = await uploadFiles(files);
        if (!uploadedFiles) return;
        payload.files = uploadedFiles;
      }

      let res;
      if (values._id) {
        await MasterNameApi.updateMasterName(payload);
      } else {
        if (isMasterIndex) {
          await MasterIndexApi.createMasterPerson(payload);
        } else {
          res = await MasterNameApi.createMasterNamePerson(payload);
          setCurrentMastername({
            kaseId,
            relationship: {
              behavior: 'N/A',
              involvement: kaseId ? undefined : [],
            },
            masterName: res.data as IMasterNamePerson,
          } as IKaseMasterName);

          handleAddLinkedName(res.data._id as string, {
            relationship: {
              behavior: 'N/A',
              involvement: kaseId ? undefined : [],
            },
          });
        }
      }

      if (showConfirm) {
        setShowConfirm(false);
        return checkDriverLicenceId(res?.data);
      }

      onGoBack();
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    } finally {
      hideLoader();
      setShowConfirm(false);
    }
  };

  const gotoDriverLicense = () => {
    if (!currentMastername) {
      setShowConfirm(true);
      return;
    }
    checkDriverLicenceId(currentMastername?.masterName as IMasterNamePerson);
  };

  const checkDriverLicenceId = (masterName?: IMasterNamePerson) => {
    const driverLicenceID =
      masterName?.driverLicense?._id || masterName?.driverLicense;
    if (driverLicenceID) {
      setCurrentDriverLicenseId(driverLicenceID.toString());
      return updateCurrentScreen(TabNamesScreens.MasterAddDriverLicense);
    } else {
      setCurrentDriverLicenseId('');
      return updateCurrentScreen(TabNamesScreens.MasterNameDriverLicense);
    }
  };

  const renderFooters: { [key: string]: any } = {
    renderIdentityFooter: useCallback(() => {
      return (
        <Grid md={12}>
          <Button color="error" sx={{ ml: 2 }} onClick={gotoDriverLicense}>
            Driver&apos;s license/ID Card
          </Button>
        </Grid>
      );
    }, []),
  };

  const getRestItems = (rest: any) => {
    if (!rest.items) return;
    const additionalFields = [];
    if (
      currentMastername?.relationship?.involvement ===
      KaseMasterNameInvolvement.VICTIM
    ) {
      const conditions = ['sex', 'race', 'ethnicOrigin'];
      if (conditions.includes(rest.name)) {
        additionalFields.push({
          label: 'Not Applicable',
          value: 'X',
        });
      }
    }

    if (
      currentMastername?.relationship?.involvement ===
        KaseMasterNameInvolvement.VICTIM ||
      currentMastername?.relationship?.involvement ===
        KaseMasterNameInvolvement.OFFENDER
    ) {
      if (rest.name === 'sex') {
        additionalFields.push({
          label: 'Not Reported',
          value: 'R',
        });
      }
    }
    return { items: [...rest.items, ...additionalFields] };
  };

  const getFullSSN = async () => {
    if (!currentMastername?.masterName?._id) return undefined;
    try {
      return await MasterNameApi.getMasterNameSSN(
        currentMastername.masterName._id,
      );
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
      return undefined;
    }
  };

  return (
    <TabAnimationWrapper nextPaths={TabNamesNextPaths.MasterAddName}>
      {!hideHeader && (
        <PageBackHeader
          title={`${currentMastername ? 'Edit' : 'Add'} person details`}
          goBack={() => {
            if (onNavigation(new Event('back'))) {
              onGoBack();
            }
          }}
          style={{
            background: 'white',
            fontWeight: 500,
            borderTopLeftRadius: '0.5rem',
            borderTopRightRadius: '0.5rem',
          }}
        />
      )}
      <FormRoot
        onSubmit={handleSubmit(onSubmit, onError)}
        sx={{
          pb: 0,
          '.p-kase-edit &': {
            padding: '40px 20px',
            mt: 0,
            background: 'white',
          },
        }}
      >
        {MasterNameFormFields.map(({ key, title, items, footer }) => (
          <ViewCard
            className="grey-title"
            key={key}
            title={title}
            style={{ fontWeight: 500 }}
          >
            {items.map(({ grid, ...rest }: any, index: number) => {
              return rest.type === 'hidden' ? (
                <input type="hidden" {...register(rest.name)} />
              ) : (
                <Grid
                  key={`${key}-${index}`}
                  xs={12}
                  sm={6}
                  md={3}
                  {...grid}
                  {...rest}
                  item
                >
                  {rest.name === 'phoneNumbers' ? (
                    <MasterNamePhoneNumberForm />
                  ) : (
                    <RenderFormField
                      {...rest}
                      getFullSSNApi={
                        rest.name === 'ssn' ? getFullSSN : undefined
                      }
                      {...getRestItems(rest)}
                    />
                  )}
                </Grid>
              );
            })}

            {footer && footer in renderFooters ? renderFooters[footer]() : null}
            {title && (
              <Divider
                style={{
                  width: '-webkit-fill-available',
                  margin: '10px 24px',
                  marginRight: '0px',
                }}
              />
            )}
          </ViewCard>
        ))}
      </FormRoot>
      <Stack
        sx={{
          background: 'white',
          borderBottomLeftRadius: '0.5rem',
          borderBottomRightRadius: '0.5rem',
        }}
      >
        <Divider sx={{ my: 2, width: '100%' }} />
        <Stack
          flexDirection="row"
          justifyContent="flex-end"
          sx={{ my: 3, '.p-kase-edit &': { p: 2 } }}
        >
          <Button
            color="inherit"
            variant="outlined"
            sx={{ px: 4, mr: 2 }}
            onClick={onGoBack}
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={handleSubmit(onSubmit, onError)}
            disabled={hasInvalidFiles}
          >
            Save
          </Button>
        </Stack>

        <ConfirmationDialog
          title="Save User"
          description="You <b>should</b> save the <b>person record</b> before entering the <b>driver's license information</b>."
          confirmText="Save user"
          open={showConfirm}
          onCancel={() => {
            setShowConfirm(false);
          }}
          onConfirm={handleSubmit(onSubmit, onError)}
        />
      </Stack>
    </TabAnimationWrapper>
  );
};

export default withLoader(withUnsavedChangesWarning(MasterNameForm, false));
