import { useNavigate } from 'react-router-dom';

import { Button, ButtonGroup } from '@mui/material';

type NavigationButtonGroupProps = {
  tabs?: { label: string; path: string; disabled?: boolean }[];
  activeTab?: string;
};
const NavigationButtonGroup = ({
  tabs,
  activeTab,
}: NavigationButtonGroupProps) => {
  if (!tabs) return null;
  const navigate = useNavigate();
  return (
    <ButtonGroup
      sx={{ backgroundColor: 'white', marginY: 2 }}
      size="large"
      aria-label="Large button group"
    >
      {tabs &&
        tabs.map((tab) => (
          <Button
            type="button"
            disabled={tab.disabled}
            sx={{
              borderColor: 'transparent',
              padding: 2,
              color: activeTab !== tab.path ? '#0E3145' : null,
            }}
            variant={activeTab === tab.path ? 'contained' : 'text'}
            color="error"
            key={tab.path}
            onClick={() => {
              navigate(tab.path, { replace: true });
            }}
          >
            {tab.label}
          </Button>
        ))}
    </ButtonGroup>
  );
};

export default NavigationButtonGroup;
