import { FC } from 'react';
import { Controller, FieldError, get, useFormContext } from 'react-hook-form';

import { FormHelperText, InputLabel, Slider } from '@mui/material';

import { InputProps } from '../Input';
import { FormElementProps } from '../types';

export interface FormRangeFieldProps
  extends Omit<InputProps, 'name' | 'onChange'>,
    FormElementProps {
  handleChange?: (name: string, value: number[]) => void;
}

export const FormRangeField: FC<FormRangeFieldProps> = (props) => {
  const { name, rules, defaultValue, handleChange, label, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name) as FieldError;

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Controller
        control={control}
        defaultValue={defaultValue || [0, 0]}
        name={name}
        render={({ field }: any) => (
          <Slider
            sx={{ ml: 2 }}
            {...rest}
            {...field}
            error={error?.message}
            name={name}
            valueLabelDisplay="on"
            onChange={(event: Event, value: Array<number>) => {
              if (handleChange != null) handleChange(name, value);
              field.onChange(event);
            }}
          />
        )}
        rules={rules}
      />
      {error?.message && (
        <FormHelperText error>{error?.message}</FormHelperText>
      )}
    </>
  );
};
