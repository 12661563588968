import { FC, useEffect, useLayoutEffect, useState } from 'react';

import {
  Box,
  Chip,
  Divider,
  IconButton,
  Stack,
  StackProps,
  Tab,
  Tabs,
} from '@mui/material';

import { CfsApi, MapLayerApi } from '@/api';
import { IcRefresh } from '@/assets/images';
import { EditableRequiredUnits, SearchBar } from '@/components';
import { LocalStorageItems } from '@/constants';
import { WithLoaderProps } from '@/hocs';
import { useCFSContext, useUnitShiftContext } from '@/hooks';
import { PolygonLayerType } from '@/models';
import { ConfirmTypes, useDataStore, useToastStore } from '@/store';

import AssignedUnits from './AssignedUnits';
import AvailableUnits from './AvailableUnits';
import OffDutyUnits from './OffDutyUnits';
import { TabPanel } from './TabPanel';
import { UnitFilter } from './UnitFilter';
import { FilterValues } from './UnitFilterForm';
import { UnitShiftListRoot } from '../styles';

interface UnitShiftListProps extends StackProps {
  cfsId?: string;
  fetchActiveCFSList?: () => void;
  isUnitShiftsPage?: boolean;
}

export interface CommonUnitsProps extends WithLoaderProps {
  cfsId?: string;
  filterValues: FilterValues;
  searchText?: string;
  handleAssignUnit?: () => void;
}

export const UnitShiftList: FC<UnitShiftListProps> = ({
  cfsId,
  fetchActiveCFSList,
  isUnitShiftsPage,
  ...rest
}) => {
  const { cfs, handleRefresh } = useCFSContext();
  const { filterValues } = useUnitShiftContext();
  const { updateData } = useDataStore();
  const { updateToast } = useToastStore();
  const [currentTab, setCurrentTab] = useState<number>(
    Number(localStorage.getItem(LocalStorageItems.UnitShiftListCurrentTab)) ??
      1,
  );
  const [shouldCancel, setShouldCancel] = useState<string>('');

  const [searchUnitText, setSearchUnitText] = useState<string>('');

  const { setSearchText } = useUnitShiftContext();

  const cfsBeatName = cfs?.addressInfo?.additionalInfo?.beat?.name;
  useLayoutEffect(() => {
    if (cfsId) localStorage.setItem(LocalStorageItems.CurrentAgencyId, '');
  }, [cfsId]);
  useEffect(() => {
    fetchBeatPolygonLayers();
  }, []);

  const fetchBeatPolygonLayers = () => {
    const beatParam = { type: PolygonLayerType.BEAT };
    MapLayerApi.getPolygonLayers({
      filter: JSON.stringify(beatParam),
      limit: 1000,
    })
      .then(({ data: { results } }) => {
        updateData({ beats: results });
      })
      .catch((err: any) => {
        updateToast({ open: true, message: err.message });
      });
  };

  const handleTabChange = (tab: number) => {
    localStorage.setItem(
      LocalStorageItems.UnitShiftListCurrentTab,
      tab.toString(),
    );
    setCurrentTab(tab);
  };

  const handleRefreshUnitShiftDistances = () => {
    CfsApi.refreshUnitShiftDistances(cfsId as string)
      .then(() => {
        updateToast({
          open: true,
          message: 'Refreshed successfully',
          type: ConfirmTypes.SUCCESS,
        });
      })
      .catch((err: any) => {
        updateToast({
          open: true,
          message: err.message,
          type: ConfirmTypes.WARNING,
        });
      });
    handleRefresh();
  };

  const handleAssignUnit = () => {
    // clear the search text and trigger the useEffect in SearchBar after assigning a unit
    setSearchUnitText('');
    // change the value of shouldCancel to trigger the useEffect in SearchBar
    setShouldCancel(Date.now().toString());
  };

  return (
    <UnitShiftListRoot {...rest} className={cfsId && 'hasCfsId'}>
      {!!cfsId && (
        <Stack className="header">
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            // flex={1}
          >
            {cfsBeatName && (
              <Chip label={cfsBeatName} className="sector-chip" />
            )}
            <div
              style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}
            >
              <EditableRequiredUnits />
            </div>
          </Stack>
          <Divider sx={{ mt: 2 }} />
        </Stack>
      )}

      <Box className="tab-box">
        <Tabs
          value={currentTab}
          onChange={(_, value) => handleTabChange(value)}
        >
          <Tab value={0} label="Available" />
          <Tab value={1} label="Assigned" />
          {!cfsId && <Tab value={2} label="Off-duty" />}
        </Tabs>
        {!!cfsId && (
          <IconButton onClick={handleRefreshUnitShiftDistances}>
            <IcRefresh />
          </IconButton>
        )}
      </Box>
      <Divider style={{ margin: '0px 8px' }} />
      <Box className="tab-box">
        <Stack
          sx={{ gap: '15px', width: '100%' }}
          flexDirection="row"
          margin="8px 0px"
        >
          <SearchBar
            onChange={(value) => {
              setSearchUnitText(value);
              setSearchText(value); // Add this line to update context
            }}
            shouldCancel={shouldCancel}
            sx={{
              marginRight: '0',
              flex: 1,
              maxWidth: '100%',
            }}
            placeholder="Search..."
          />

          <UnitFilter />
        </Stack>
      </Box>
      <TabPanel value={0} index={currentTab}>
        <AvailableUnits
          cfsId={cfsId}
          filterValues={filterValues}
          searchText={searchUnitText}
          setCurrentTab={handleTabChange}
          fetchActiveCFSList={fetchActiveCFSList}
          handleAssignUnit={handleAssignUnit}
          showMenu={isUnitShiftsPage}
        />
      </TabPanel>
      <TabPanel value={1} index={currentTab}>
        <AssignedUnits
          cfsId={cfsId}
          filterValues={filterValues}
          searchText={searchUnitText}
          handleAssignUnit={handleAssignUnit}
        />
      </TabPanel>
      <TabPanel value={2} index={currentTab}>
        <OffDutyUnits filterValues={filterValues} searchText={searchUnitText} />
      </TabPanel>
    </UnitShiftListRoot>
  );
};
