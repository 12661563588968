import { AxiosResponse } from 'axios';

import { ApiVersion } from '@/config';
import {
  IMasterNameOrganization,
  IMasterOrganizationRequest,
  IMasterVehicle,
  IMasterNamePerson,
  IMasterNameReq,
  IMasterVehicleForm,
  IProperty,
  IMasterIndexRelatedItems,
  IMasterAddress,
  IMasterAddressListResponse,
  IMasterAddressListParams,
  IMasterIndexLog,
} from '@/models';

import { http } from './http';
import { IGetParams, IListResponse } from './types';

export enum MasterIndexApiPath {
  Addresses = 'addresses',
  Organizations = 'organizations',
  People = 'people',
  Properties = 'properties',
  Vehicles = 'vehicles',
  Logs = 'logs',
}

export const MasterIndexApi = {
  getMasterIndexLogs: (
    params: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IMasterIndexLog>>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Logs}`,
      { params },
    );
  },
  getMasterVehicles: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IMasterVehicle>>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Vehicles}`,
      { params },
    );
  },
  createMasterVehicle: (
    data: IMasterVehicleForm,
  ): Promise<AxiosResponse<IMasterVehicle>> => {
    return http.post(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Vehicles}`,
      data,
    );
  },
  updateMasterVehicle: ({
    _id,
    ...data
  }: IMasterVehicleForm): Promise<AxiosResponse<IMasterVehicle>> => {
    return http.put(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Vehicles}/${_id}`,
      data,
    );
  },
  deleteMasterVehicle: (id: string): Promise<AxiosResponse<null>> => {
    return http.delete(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Vehicles}/${id}`,
    );
  },
  getMasterVehicle: (id: string): Promise<AxiosResponse<IMasterVehicle>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Vehicles}/${id}`,
    );
  },
  getMasterPersons: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IMasterNamePerson>>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.People}`,
      { params },
    );
  },
  getMasterPerson: (id: string): Promise<AxiosResponse<IMasterNamePerson>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.People}/${id}`,
    );
  },
  getMasterRelatedRecords: (
    type: MasterIndexApiPath,
    id: string,
    params?: IGetParams,
  ): Promise<AxiosResponse<IMasterIndexRelatedItems[]>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-index/${type}/related-records/${id}`,
      { params },
    );
  },
  createMasterPerson: (
    data: IMasterNameReq,
  ): Promise<AxiosResponse<IMasterNamePerson>> => {
    return http.post(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.People}`,
      data,
    );
  },
  deleteMasterPerson: (
    id: string,
  ): Promise<AxiosResponse<IMasterNamePerson>> => {
    return http.delete(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.People}/${id}`,
    );
  },
  getMasterOrganizations: (
    params?: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IMasterNameOrganization>>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Organizations}`,
      { params },
    );
  },
  getMasterOrganization: (
    id: string,
  ): Promise<AxiosResponse<IMasterNameOrganization>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Organizations}/${id}`,
    );
  },
  getMasterOrganizationRelatedRecords: (
    id: string,
    params?: IGetParams,
  ): Promise<AxiosResponse<any>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Organizations}/related-records/${id}`,
      { params },
    );
  },
  createMasterOrganization: (
    data: IMasterOrganizationRequest,
  ): Promise<AxiosResponse<IMasterNameOrganization>> => {
    return http.post(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Organizations}`,
      data,
    );
  },
  updateMasterOrganization: (
    data: IMasterOrganizationRequest,
  ): Promise<AxiosResponse<IMasterNameOrganization>> => {
    return http.put(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Organizations}/${data._id}`,
      data,
    );
  },
  deleteMasterOrganization: (
    id: string,
  ): Promise<AxiosResponse<IMasterNameOrganization>> => {
    return http.delete(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Organizations}/${id}`,
    );
  },
  getProperty: async (id: string): Promise<AxiosResponse<IProperty>> => {
    return http.get(`api/${ApiVersion.V1}/master-index/properties/${id}`);
  },
  getPropertyRelatedRecords: async (
    id: string,
  ): Promise<AxiosResponse<IProperty>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-index/properties/related-records/${id}`,
    );
  },
  getPropertyList: async (
    params: IGetParams,
  ): Promise<AxiosResponse<IListResponse<IProperty>>> => {
    return http.get(`api/${ApiVersion.V1}/master-index/properties`, { params });
  },
  getMasterAddresses: (
    params?: IMasterAddressListParams,
  ): Promise<AxiosResponse<IMasterAddressListResponse>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Addresses}`,
      { params },
    );
  },

  getMasterAddress: (id: string): Promise<AxiosResponse<IMasterAddress>> => {
    return http.get(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Addresses}/${id}`,
    );
  },

  createMasterAddress: (
    data: Partial<IMasterAddress>,
  ): Promise<AxiosResponse<IMasterAddress>> => {
    return http.post(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Addresses}`,
      data,
    );
  },

  updateMasterAddress: (
    id: string,
    data: Partial<IMasterAddress>,
  ): Promise<AxiosResponse<IMasterAddress>> => {
    return http.put(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Addresses}/${id}`,
      data,
    );
  },

  deleteMasterAddress: (id: string): Promise<AxiosResponse<void>> => {
    return http.delete(
      `api/${ApiVersion.V1}/master-index/${MasterIndexApiPath.Addresses}/${id}`,
    );
  },
};
