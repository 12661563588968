import { IEquipmentType } from '@/models/equipment';

import { AgencyType } from './agency';
import { IAuditModel, ObjectId } from './base';

export enum UnitStatus {
  ON_DUTY = 'ON_DUTY',
  OFF_DUTY = 'OFF_DUTY',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
}

export enum AssignedUnitShiftStatus {
  ASSIGNED = 'ASSIGNED',
  EN_ROUTE = 'EN_ROUTE',
  ON_SCENE = 'ON_SCENE',
  PROGRESS = 'PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  QUEUED = 'QUEUED',
}

export enum ResourceType {
  VEHICLE = 'VEHICLE',
  PEOPLE = 'PEOPLE',
  EQUIPMENT = 'EQUIPMENT',
  POLYGON_LAYER = 'POLYGON_LAYER',
  LINE_LAYER = 'LINE_LAYER',
  POINT_LAYER = 'POINT_LAYER',
}

export interface IResource {
  type: ResourceType;
  subType?: IEquipmentType | null;
  min: number;
  max?: number;
  withUnit: boolean;
}

export interface IUnitType extends ObjectId {
  name: string;
  icon?: string;
  agencyType: AgencyType;
  resources: IResource[];
  isDispatchToHQEnabled?: boolean;
  closeUnitShiftWithCFS?: boolean;
  deleted?: boolean;
}

export interface IUnitFilterForm {
  _id?: string;
  name: string;
  agencies?: string[] | null;
  agencyType?: AgencyType | null;
  beat?: string | null;
  unitTypes?: string[] | null;
}

export interface FilterValues {
  agencies?: string[] | null;
  agencyType?: AgencyType | null;
  beat?: string | null;
  unitTypes?: string[] | null;
}

export interface IUnitFilter
  extends Omit<IUnitFilterForm, '_id'>,
    IAuditModel {}

export interface IUnitResourceForm {
  vehicles?: string[];
  users?: string[];
  equipments?: string[];
  polygons?: string[];
  lines?: string[];
  points?: string[];
}

export interface IUnitShiftForm extends IUnitResourceForm {
  unit: string;
}

export interface IUnitForm extends ObjectId, IUnitResourceForm {
  name: string;
  type: string;
  status?: UnitStatus;
  isStandByWhenOffDuty: boolean;
}

export interface IUnit extends IAuditModel, Omit<IUnitForm, 'type'> {
  _id: string;
  agency: string;
  type: IUnitType;
  status: UnitStatus;
  beat?: string;
}
