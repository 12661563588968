import { useEffect, useMemo, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';

import { MasterNameApi } from '@/api';
import { TableContainer } from '@/components';
import { DATE_FORMAT } from '@/config';
import {
  PageBackHeader,
  TabAnimationWrapper,
  useTabLayoutContext,
} from '@/layouts/TabLayout';
import { ICFSMasterName } from '@/models/cfs';
import { useToastStore } from '@/store';
import { flattenObject } from '@/utils';

import {
  TabNamesNextPaths,
  TabNamesScreens,
  cfsHistoryTableColumns,
} from '../../data';
import { useMasterNamesContext } from '../../MasterNamesContext';

export const CFSInvolvementHistory = () => {
  const { updateToast } = useToastStore();
  const { updateCurrentScreen } = useTabLayoutContext(); 
  const { currentMastername } = useMasterNamesContext();

  const [historyRows, setHistoryRows] = useState<ICFSMasterName[]>();

  useEffect(() => {
    fetchCfsHistory();
  }, [currentMastername?.masterName._id]);

  const fetchCfsHistory = async () => {
    if (!currentMastername?.masterName._id) return;
    try {
      const res = await MasterNameApi.getNameCfsHistory(
        currentMastername?.masterName._id,
      );
      setHistoryRows(res.data);
    } catch (err: any) {
      updateToast({ open: true, message: err.message });
    }
  };

  const rows = useMemo(() => {
    return historyRows?.map((masterVehicle) => flattenObject(masterVehicle));
  }, [historyRows]);
  
  const onGoBack = () => {
    updateCurrentScreen(TabNamesScreens.MasterNameView);
  };

  const renderCell = (row: Record<string, any>, key: string) => {
    const value = row[key as keyof typeof row]?.toString();
    return key === 'createdAt' && value !== ''
      ? moment(value).format(DATE_FORMAT)
      : value;
  };

  return (
    <TabAnimationWrapper nextPaths={TabNamesNextPaths.MasterNameView}>
      <PageBackHeader title="CFS involvement history" goBack={onGoBack} />
      <TableContainer
        sx={{
          px: 0,
          mt: 2,
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {cfsHistoryTableColumns.map((field) => (
                <TableCell key={field.key}>{field.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row._id}>
                {cfsHistoryTableColumns.map((field: any) => {
                  return (
                    <TableCell key={field.key}>
                      {renderCell(row, field.key)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TabAnimationWrapper>
  );
};
